import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import Agreement from '../components/Agreement';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Grid } from '@material-ui/core';
// import bankSignature from '../signature.jpg';
import { makeStyles } from '@material-ui/styles';
import bankSignature from '../sign2.jpg';

import axios from 'axios';

const useStyles = makeStyles( theme => ({
  clearButton: {
    fontSize: '0.6rem',
  },
  acceptBtn: {
    marginTop: 26,
    fontSize: '1.5rem',
    color: '#FAFAFA',
    background: '#8bc34a',
    '&:hover': {
      background: '#6e9b3b',
    }
  },
  bankSignature: {
    width: '100%',
    maxWidth: 200,
    height: 'auto'
  }
}));

function generateRequest(params) {
  return {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify(params)
  };
}

export default function Client() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userData, setItems] = useState([]);
  const [image, setImage] = useState(null);
  const [agreement, setAgreement] = useState(null);

  const classes = useStyles();
  const signatureEl = useRef(null);

  const params = useParams();

  const API_URL = `${window.location.protocol}//${window.location.host}/`;

  const clear = () => {
    signatureEl.current.clear();
  };

  const accept = () => {
    if(signatureEl.current.isEmpty()) {
      return window.alert('Вы не подписали договор');
    }
    const image = signatureEl.current.getTrimmedCanvas().toDataURL('image/png');
    setImage(image);

    fetch(API_URL + 'api.php', generateRequest({ method: 'saveAgreement', agreementID: params.id, image }))
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  useEffect(() => {
    fetch(API_URL  + 'api.php', generateRequest({ method: 'getAgreement', agreementID: params.id }))
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
          if(result.image !== 'none') {
            setImage(`${API_URL}signs/${result.image}.png`);
          }
          const pathToFile = result.modified > 0 ? API_URL + 'agreements/' + params.id + '.html' : API_URL + 'agreements/default.html';
          fetch(pathToFile)
            .then((r) => r.text(), (error) => {
              setIsLoaded(true);
              setError(error);
            })
            .then(text  => {
              setAgreement(text);
              setIsLoaded(true);
          }, (error) => {
            setIsLoaded(true);
            setError(error);
          });
          if(result.result === 'not_found') {
            setError('Не найдено');
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []);

  if(error) {
    return <p>{error}</p>;
  }

  return <>
    <Agreement userData={userData} agreement={agreement} isLoaded={isLoaded} />
    <hr/>
    <Grid container>
      <Grid item md={6} xs={12}>
        БАНК: Stanleyspta<br/>
        АДРЕС: 29 Fuchengmen Outer St, Xicheng District, Beijing, Китай, 100044<br/>
        НАЛОГОВЫЙ ИДЕНТИФИКАТОР: DNW081124<br/>
        КОД БАНКА: 30022000<br/>
        LEI: 549300WHU4EIHRP28H15<br/>
        Подпись сотрудника банка<br />
        <img src={bankSignature} className={classes.bankSignature} />
      </Grid>
      <Grid item  md={6} xs={12}>
        ЗАЕМЩИК: Идентификатор заёмщика: 0094S491<br/><br/>
        Подпись заёмщика<br/>
        {!image &&<div style={{ border: '1px solid #000', display: 'inline-block', position: 'relative' }}>
          <p align="center" style={{ color: '#FAFAFA', userSelect: 'none', padding: 0, margin: 0 }}>Подпись заёмщика</p>
          <SignatureCanvas penColor='#000' backgroundColor='#FFF' ref={signatureEl} canvasProps={{width: 300, height: 200 }} />
          <Button onClick={clear} style={{ position: 'absolute', bottom: 0, right: 0 }} classes={{ root: classes.clearButton }}>очистить</Button>
        </div> }
        { image && <img src={image} style={{ width: 200, height: 'auto' }} /> }
      </Grid>
      
      { !image && <>
        <Grid item xs={12}>
          <Button fullWidth variant="solid" color="primary" classes={{ root: classes.acceptBtn }} onClick={accept} >Подписать и сохранить</Button>
        </Grid>
        <Grid item xs={12}>
          при нажатии на кнопку подписи вы соглашаетесь с договором
        </Grid>
      </> }
    </Grid>
  </>;

  return <p>Данные {params.id}</p>;
}